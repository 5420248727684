import {Serializer} from '@matchsource/api-utils';
import {OrderTypeDto, Service} from '@matchsource/api-generated/orders';
import {OrderTypeModel, OrderTypeServiceModel} from '@matchsource/models/order';

// All order types extend some basic class. Additional fields of a particular order type are not included in DTO.
type ServiceExtended = Service & {children: ServiceExtended[]};

const mapService = (input: ServiceExtended): OrderTypeServiceModel => ({
  children: input.children ? input.children.map(child => mapService(child)) : [],
  code: input.code,
  description: input.description,
  type: {
    code: input.type?.code,
    description: input.type?.description,
  },
});

export const orderTypeSerializer: Serializer<OrderTypeModel, OrderTypeDto> = {
  fromDTO(input: OrderTypeDto): OrderTypeModel {
    return {
      description: input.description,
      orderType: input.orderType,
      services: input.services ? input.services.map(service => mapService(service as ServiceExtended)) : [],
    };
  },
};
