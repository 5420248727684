import {Serializer} from '@matchsource/api-utils';
import {OrderModelSubmitted} from '@matchsource/models/order';
import {CreateOrderResult} from '@matchsource/api-generated/orders';

export const submittedOrderSerializer: Serializer<OrderModelSubmitted, CreateOrderResult> = {
  fromDTO(input: CreateOrderResult): OrderModelSubmitted {
    return {
      orderId: input.orderId,
      searchStarted: input.searchStarted,
      sourceGuid: input.sourceGuid,
      status: input.status,
      reason: {
        code: input.reason?.code,
        reasonDetails: input.reason?.reasonDetails,
      },
    };
  },
};
