import {MapTo} from '@matchsource/api-utils';
import {Source} from '@matchsource/api-generated/orders';
import {SourceCoreModel} from '@matchsource/models/source';

export const mapSourceListToSource: MapTo<SourceCoreModel, Source> = (model: SourceCoreModel): Source => ({
  ...('isAbOnly' in model ? {abOnly: model.isABOnly} : {}),
  bpGuid: model.bpGuid,
  guid: model.id,
  status: model.status,
  sourceType: model.type.toUpperCase() as 'CORD' | 'DONOR',
  productType: model.productType,
  ct: model.previousCT,
});
