import {Injectable} from '@angular/core';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  WorkupDetailsFactory,
  OrderTrackingBySourceGuidAndTypeModel,
  OrderTrackingByTypeModel,
  CreatedOrderModel,
  GetOrderableSourcesResponse,
  OrderModel,
  OrderTrackingUpdateModel,
  OrderWithTrackingsModel,
  SubmittedOrderModel,
  OrderTypeModel,
  isNewDonorWorkupSubmittedOrderModel,
  OrderableReason,
  ORDERABLE_STATE,
} from '@matchsource/models/order';
import {firstValueFrom, forkJoin, iif, Observable, of, throwError, zip} from 'rxjs';
import {toTimestamp} from '@matchsource/date';
import {PatientApiService} from '@matchsource/api/patient';
import {SourceOrderHistoryFactoryService} from './source-order-history-factory.service';
import {ensureArray, isEmptyObject, toMap, uniq} from '@matchsource/utils';
import {PatientLookupModel} from '@matchsource/models/patient';
import {TRACE_ID_HEADER_KEY} from '@matchsource/api-utils';
import {
  OrderableRuleControllerService,
  OrderControllerService,
  OrderTrackingControllerService,
  OrderTypeControllerService,
  StatusOfSource,
  StrictHttpResponse,
  CreateOrderResult,
  OrderRecipient,
  OrderRequested,
} from '@matchsource/api-generated/orders';
import {CordListModel} from '@matchsource/models/cord';
import {mapSourceListToSource} from './utils';
import {SourceCoreModel} from '@matchsource/models/source';
import flow from 'lodash-es/flow';
import mapValues from 'lodash-es/mapValues';
import {MatchCriteriaDto} from '@matchsource/api-generated/search-match';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode, BasicError} from '@matchsource/error-handling/core';
import {orderSerializer} from './order.serializer';
import {orderTrackingSerializer} from './order-tracking.serializer';
import {orderTypeSerializer} from './order-type.serializer';
import {submittedOrderSerializer} from './submitted-order.serializer';
import {MatchResultsApiService} from '@matchsource/api/match-results';
import {BiobankListModel} from '@matchsource/models/biobank';
import {mapWorkupModelToDto} from '@matchsource/api/donor-workup';
import {DonorListModel} from '@matchsource/models/donor';
import {ERROR_NOTIFICATION, skipError, UserService, useSpinner} from '@matchsource/core';
import {EventService} from '@matchsource/event';

interface ErrorModel {
  isError: boolean;
  error: any;
}

function isErrorType(object: any): object is ErrorModel {
  return 'isError' in object && object.error.status === 403;
}

function mapResponseToBodyWithTrace() {
  return function mapResponseToBodyWithRedirectToSearch(
    source: Observable<StrictHttpResponse<CreateOrderResult[]>>
  ): Observable<CreatedOrderModel> {
    return source.pipe(
      map(response => {
        const {body, headers} = response;
        const redirectToSearch = headers.get('x-source-search') === 'run' || (!!body && !!body[0].searchStarted);
        const order = body ? body.map(createdOrderType => submittedOrderSerializer.fromDTO(createdOrderType)) : [];

        return {order, redirectToSearch};
      })
    );
  };
}

const INFUSION_DATE_UPDATE_SOURCE = 'NMDP.MatchSource';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService {
  constructor(
    private readonly patientApi: PatientApiService,
    private readonly sourceOrderHistoryFactory: SourceOrderHistoryFactoryService,
    private readonly orderControllerService: OrderControllerService,
    private readonly events: EventService,
    private readonly user: UserService,
    private readonly orderableRuleControllerService: OrderableRuleControllerService,
    private readonly matchResultControllerApiService: MatchResultsApiService,
    private readonly orderTrackingControllerService: OrderTrackingControllerService,
    private readonly orderTypeControllerService: OrderTypeControllerService
  ) {}

  getOrder(orderId: MsApp.Guid): Observable<OrderModel> {
    return this.orderControllerService.loadOrder({guid: orderId}).pipe(map(order => orderSerializer.fromDTO(order)));
  }

  getOrders(patientId: MsApp.Guid): Observable<OrderModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingPatientOrderData);

    return this.orderControllerService
      .loadAllOrders1({recipientGuid: patientId}, {context: context()})
      .pipe(map(orders => orders.map(order => orderSerializer.fromDTO(order))));
  }

  getOrderTrackings(orderId: MsApp.Guid): Observable<OrderTrackingByTypeModel> {
    return this.orderControllerService
      .loadOrderTrackings1({guid: orderId})
      .pipe(
        map(response => mapValues(response, orders => orders.map(order => orderTrackingSerializer.fromDTO(order))))
      );
  }

  getTrackings(orderIds: MsApp.Guid[]): Observable<OrderTrackingBySourceGuidAndTypeModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingOrderData);

    return this.orderControllerService
      .loadOrderTrackings({body: orderIds}, {context: context()})
      .pipe(
        map(response =>
          mapValues(response, values =>
            mapValues(values, orders => orders.map(order => orderTrackingSerializer.fromDTO(order)))
          )
        )
      );
  }

  getOrdersWithTrackings(patientId: MsApp.Guid): Observable<OrderWithTrackingsModel[]> {
    return this.getOrders(patientId).pipe(
      switchMap(orders =>
        iif(
          () => !orders.length,
          of([]),
          this.getTrackings(orders.map(item => item.id)).pipe(
            map(trackings =>
              orders.map(order => {
                const orderTrackings = WorkupDetailsFactory.create(trackings[order.id], order.type) as any;

                let resultsReceivedDate: string = null;
                if (orderTrackings.resultsReceivedDate.items.every((item: any) => item.hasValue)) {
                  [{value: resultsReceivedDate}] = orderTrackings.resultsReceivedDate.items.sort(
                    (first: any, second: any) => toTimestamp(second.date) - toTimestamp(first.date)
                  );
                }

                return {
                  ...order,
                  preparationStart: orderTrackings.prepStart.getItemValue(0),
                  collection1: orderTrackings.collection.getItemValue(0),
                  collection2: orderTrackings.collection.getItemValue(1),
                  clearance: orderTrackings.clearance.getItemValue(0),
                  ctShipDate: orderTrackings.ctShipDate.getItemValue(0),
                  cordShipDate: orderTrackings.cordShipDate.getItemValue(0),
                  cordPreparationStart: orderTrackings.cordPrepStart.getItemValue(0),
                  appointmentDate: orderTrackings.appointmentDate.getItemValue(0),
                  cryopreservedShipmentDate: orderTrackings.cryopreservedShipmentDate.getItemValue(0),
                  resultsReceivedDate,
                } as OrderWithTrackingsModel;
              })
            )
          )
        )
      )
    );
  }

  private getOrderable(patientId: MsApp.Guid, sources: Array<SourceCoreModel>): Observable<StatusOfSource[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingOrderData);
    return this.orderableRuleControllerService
      .getOrderableStatus(
        {
          recipientGuid: patientId,
          body: sources.map(source => mapSourceListToSource(source)),
        },
        {
          context: context(),
        }
      )
      .pipe(map(result => result.statuses));
  }

  getOrderableSources(
    patientId: MsApp.Guid,
    sources: Array<DonorListModel | CordListModel | BiobankListModel>
  ): Observable<GetOrderableSourcesResponse> {
    return this.getOrderable(patientId, sources).pipe(
      map(statusOfSources => {
        const mapStatusOfSources = toMap(statusOfSources, 'sourceGuid');

        // @TODO: Should be refactored after migration to avoid object mutation
        return sources.map(source => {
          const status = mapStatusOfSources[source.id];
          const orderableStatus = status?.orderableStatus || {};
          const orderableReasons = orderableStatus.reasons || [];

          source.orderable = !!ORDERABLE_STATE[orderableStatus.orderable];
          source.orderableForCmOnly = orderableReasons.some(reason => reason === OrderableReason.CmOnly);
          source.orderableRecipientClosed = orderableReasons.some(reason => reason === OrderableReason.RecipientClosed);
          source.orderableSameDayOrder = orderableReasons.some(reason => reason === OrderableReason.SameDayOrders);
          source.duplicateRecipient = orderableReasons.some(reason => reason === OrderableReason.DuplicateRecipient);
          source.orderableStatus = {
            orderable: orderableStatus.orderable,
            reasons: orderableStatus.reasons,
          };

          return source;
        });
      })
    );
  }

  getSourceOrders(sourceId: MsApp.Guid): Observable<OrderModel[]> {
    return this.orderControllerService
      .loadAllOrders1({sourceGuid: sourceId})
      .pipe(map(orders => orders.map(order => orderSerializer.fromDTO(order))));
  }

  getSourceOrdersHistory(sourceId: MsApp.Guid) {
    return this.getSourceOrders(sourceId).pipe(
      switchMap(orders => {
        const patientIds = orders.map(order => order.recipientGuid).filter(guid => guid);

        return zip(
          of(orders),
          iif(
            () => patientIds.length > 0,
            this.patientApi
              .search({guids: patientIds})
              .pipe(map(({data = []}) => toMap<PatientLookupModel>(data, 'id'))),
            of([])
          )
        );
      }),
      map(([orders, patientsMap]) =>
        orders.map(order =>
          this.sourceOrderHistoryFactory.create(
            order,
            patientsMap[order.recipientGuid] ? patientsMap[order.recipientGuid] : null
          )
        )
      ),
      switchMap(orders => {
        const patientIds = orders
          .map(order => order.recipientGuid)
          .filter(guid => guid)
          .map(guid =>
            this.patientApi.get(guid, true).pipe(
              catchError(err => {
                if (err.status === 403) {
                  return of({isError: true, error: err} as ErrorModel);
                } else if (err.status === 400) {
                  // Keep existing behavior by showing generic technical difficulties pop-up when the error is HTTP 400 (which does not cause showing the pop-up in the interceptor)
                  const traceId = err.headers?.get(TRACE_ID_HEADER_KEY);
                  this.events.dispatch<BasicError>(ERROR_NOTIFICATION, {traceId, originalError: err});
                }
                return throwError(() => err);
              })
            )
          );

        return zip(
          of(orders),
          iif(() => patientIds.length > 0, forkJoin(patientIds), of([]))
        );
      }),
      map(([orders, isSameTc]) => {
        return orders.map((order, i) => {
          const isError =
            isErrorType(isSameTc[i]) ||
            !order.patient ||
            (this.user.isTCC() && !this.user.getBPIds().find(id => id === order.recipientTcId));

          return {
            ...order,
            isSameTc: !isError,
          };
        });
      })
    );
  }

  getOrdersByIds(orderIds: MsApp.Guid[]): Observable<OrderModel[]> {
    const orderIdList = uniq(ensureArray(orderIds));

    if (orderIds.length === 0) {
      return of([]);
    }

    return this.orderControllerService
      .loadOrders({body: orderIdList})
      .pipe(map(orders => orders.map(order => orderSerializer.fromDTO(order))));
  }

  getTrackingsByIds(trackingIds: number[]): Observable<OrderTrackingByTypeModel> {
    const trackingIdList = ensureArray(trackingIds);

    if (trackingIdList.length === 0) {
      return of({});
    }

    return this.orderTrackingControllerService
      .getOrderTrackingByIds({body: trackingIdList})
      .pipe(
        map(response => mapValues(response, orders => orders.map(order => orderTrackingSerializer.fromDTO(order))))
      );
  }

  updateOrder(orderId: MsApp.Guid, data: OrderRecipient): Observable<OrderTrackingByTypeModel> {
    data.infusionDateUpdateSource = INFUSION_DATE_UPDATE_SOURCE;
    return this.orderControllerService.updateOrder({guid: orderId, body: data});
  }

  updateOrderTrackings(orderId: MsApp.Guid, data: OrderTrackingUpdateModel): Observable<number> {
    return this.orderControllerService.updateOrderTracking({guid: orderId, body: data});
  }

  getOrderTrackingsHistory(orderTrackingsIds: number[]): Observable<OrderTrackingByTypeModel> {
    if (!orderTrackingsIds || orderTrackingsIds.length === 0) {
      return of({});
    }

    return this.orderTrackingControllerService
      .getOrderTrackingByIds({depth: 2, body: orderTrackingsIds})
      .pipe(
        map(response => mapValues(response, orders => orders.map(order => orderTrackingSerializer.fromDTO(order))))
      );
  }

  private getAvailableOrderTypes(
    patientId: MsApp.Guid,
    sourceIds: MsApp.Guid[],
    sourceType: 'CORD' | 'DONOR' | 'BDP'
  ): Observable<OrderTypeModel[]> {
    return this.orderTypeControllerService
      .checkAvailability({
        body: {recipientId: patientId, guids: sourceIds, sourceType},
      })
      .pipe(map(orderTypes => orderTypes.map(orderType => orderTypeSerializer.fromDTO(orderType))));
  }

  getDonorAvailableOrderTypes(patientId: MsApp.Guid, sourceIds: MsApp.Guid[]) {
    return this.getAvailableOrderTypes(patientId, sourceIds, 'DONOR');
  }

  getCordAvailableOrderTypes(patientId: MsApp.Guid, sourceIds: MsApp.Guid[]) {
    return this.getAvailableOrderTypes(patientId, sourceIds, 'CORD');
  }

  getBiobankAvailableOrderTypes(patientId: MsApp.Guid, sourceIds: MsApp.Guid[]) {
    return this.getAvailableOrderTypes(patientId, sourceIds, 'BDP');
  }

  getSourcesOrders(sourceIds: MsApp.Guid[], orderStatus: string, backgroundRequest = false): Observable<OrderModel[]> {
    if (sourceIds.length === 0) {
      return of([]);
    }

    return this.orderControllerService
      .loadAllOrders(
        {
          orderStatus,
          body: sourceIds.filter(sourceId => sourceId),
        },
        {
          context: flow(skipError, useSpinner(backgroundRequest))(),
        }
      )
      .pipe(map(orders => orders.map(order => orderSerializer.fromDTO(order))));
  }

  submitOrders(orders: SubmittedOrderModel[]): Observable<CreatedOrderModel> {
    const ordersDto = orders.map(order => {
      if (isNewDonorWorkupSubmittedOrderModel(order) && !isEmptyObject(order.workup)) {
        return {
          ...order,
          workup: mapWorkupModelToDto(order.workup, {
            completed: true,
            sourceId: order.sourceGuid,
            patientId: order.recipientGuid,
          }),
          // @TODO:  Should be refactored to avoid string for type and sourceType in application
          sourceType: order.sourceType as 'DONOR' | 'CORD' | 'BDP',
          type: order.type as
            | 'CT'
            | 'DR'
            | 'HW'
            | 'HR'
            | 'WU'
            | 'PBSC'
            | 'WB'
            | 'EWORKUP'
            | 'UW'
            | 'CCT'
            | 'HE'
            | 'OR'
            | 'AT'
            | 'BDPOR',
        };
      }

      return {
        ...order,
        // @TODO:  Should be refactored to avoid string for type and sourceType in application
        sourceType: order.sourceType as 'DONOR' | 'CORD' | 'BDP',
        type: order.type as
          | 'CT'
          | 'DR'
          | 'HW'
          | 'HR'
          | 'WU'
          | 'PBSC'
          | 'WB'
          | 'EWORKUP'
          | 'UW'
          | 'CCT'
          | 'HE'
          | 'OR'
          | 'AT'
          | 'BDPOR',
      };
    });

    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingSourceData);
    return this.orderControllerService
      .placeOrder$Response({body: ordersDto}, {context: context()})
      .pipe(mapResponseToBodyWithTrace());
  }

  cordsOmidubicelCheck(recipientGuid: string, sourceIds: MsApp.Guid[]): Observable<boolean> {
    const reqParams = {
      recipientGuid,
      sequenceNumber: 1,
      alleleReveal: false,
      pageNumber: 0,
      pageSize: 50,
      matchSourceResultFilter: {
        commercialCellularTherapies: {
          omidubicel: true,
        },
        sources: sourceIds,
      },
      sourceType: 'CORD',
    };
    return this.matchResultControllerApiService
      .search(reqParams as MatchCriteriaDto, {skipSpinner: false})
      .pipe(map(response => !!response?.totalNumberOfResults));
  }

  getPatientOrdersByStatusCount(recipientGuid: MsApp.Guid, orderStatus: string): Observable<number> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingPatientOrderData);

    return this.orderControllerService
      .loadAllOrders1(
        {
          recipientGuid,
          orderStatus,
        },
        {context: context()}
      )
      .pipe(map(orders => orders.length));
  }

  isCtDonorRequested(
    recipientGuid: MsApp.Guid,
    sourceGuid: MsApp.Guid,
    bpGuid: MsApp.Guid
  ): Observable<OrderRequested> {
    return this.orderControllerService.wasCtRequestedForSourceAndBusinessParty({
      recipientGuid,
      sourceGuid,
      bpGuid,
    });
  }

  getPatientOrdersByStatusCountPromise(recipientGuid: MsApp.Guid, orderStatus: string): Promise<number> {
    return firstValueFrom(this.getPatientOrdersByStatusCount(recipientGuid, orderStatus));
  }
}
