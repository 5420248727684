import {Injectable} from '@angular/core';
import {PatientLookupModel} from '@matchsource/models/patient';
import {OrderModel} from '@matchsource/models/order';
import {SourceOrderHistoryModel} from './source-order-history.model';

@Injectable({
  providedIn: 'root',
})
export class SourceOrderHistoryFactoryService {
  create(order: OrderModel, patient: PatientLookupModel): SourceOrderHistoryModel {
    return {
      ...order,
      patient,
    };
  }
}
