import {IOrderTrackingSourceModel, OrderTrackingModel} from './order-tracking.model';
import {CRYO_SERVICE_CODE, OrderTypeCodeInterfaceType, ServiceTypeCodeType, TYPES} from './constants';

export class OrderTrackingSourceModel implements IOrderTrackingSourceModel {
  constructor(
    public readonly orderTypeCode: OrderTypeCodeInterfaceType,
    public readonly serviceTypeCode: ServiceTypeCodeType,
    public readonly serviceCodeRule: RegExp,
    public readonly serviceCode: string,
    public readonly trackingCode: string,
    public readonly isDefault = true,
    public readonly type = TYPES.DATE
  ) {}

  isEqual(source: OrderTrackingModel): boolean {
    return (
      source.orderTypeCode === this.orderTypeCode &&
      (!this.serviceTypeCode || this.serviceTypeCode === source.serviceTypeCode) &&
      (!this.serviceCodeRule || this.serviceCodeRule.test(source.serviceCode)) &&
      (this.serviceCode === CRYO_SERVICE_CODE || source.trackingCode === this.trackingCode)
    );
  }
}
