import {LegacyWorkupSubmittedOrderModel, NewWorkupSubmittedOrderModel, SubmittedOrderModel} from './declarations';

export function isLegacyWorkupSubmittedOrderModel(
  order: SubmittedOrderModel
): order is LegacyWorkupSubmittedOrderModel {
  return (order as LegacyWorkupSubmittedOrderModel).answers !== undefined;
}

export function isNewDonorWorkupSubmittedOrderModel(order: SubmittedOrderModel): order is NewWorkupSubmittedOrderModel {
  return (order as NewWorkupSubmittedOrderModel).workup !== undefined;
}
