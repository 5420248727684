import {Serializer} from '@matchsource/api-utils';
import {OrderTracking} from '@matchsource/api-generated/orders';
import {formatDateWithoutUTC} from '@matchsource/utils';
import {OrderTrackingModel} from '@matchsource/models/order';

export const orderTrackingSerializer: Serializer<OrderTrackingModel, OrderTracking> = {
  fromDTO(input: OrderTracking): OrderTrackingModel {
    return {
      id: input.id,
      lineItemSeqNum: input.lineItemSeqNum,
      valueDate: input.valueDate ? formatDateWithoutUTC(input.valueDate) : null,
      valueText: input.valueText,
      valueNumber: input.valueNumber,
      serviceCode: input.serviceCode,
      serviceDesc: input.serviceDesc,
      serviceTypeCode: input.serviceTypeCode,
      stageCode: input.stageCode,
      trackingCode: input.trackingCode,
      orderTypeCode: input.orderTypeCode,
      subsequent: input.subsequent,
    };
  },
};
