import {parseDateTime} from '@matchsource/utils';
import flatten from 'lodash-es/flatten';
import {DATE_FORMAT_FULL, formatViewDate} from '@matchsource/date';
import {
  CBURCVD_TRACKING_CODE,
  IOrderSources,
  IOrderTrackingItem,
  IOrderTrackingSerializable,
  SHIPDATE_TRACKING_CODE,
} from './declarations';
import {CRYO_SERVICE_CODE, TYPES} from './constants';
import {OrderTrackingSourceModel} from './order-tracking-source.model';
import {OrderTrackingModel} from './order-tracking.model';

class OrderTrackingItem implements IOrderTrackingSerializable, IOrderTrackingItem {
  id: number | string;
  order: number;

  constructor(
    data: OrderTrackingModel = {},
    public source: OrderTrackingSourceModel
  ) {
    this.id = data.id;
    this.order = data.lineItemSeqNum || 1;
    this.source = source;
  }

  get isNew(): boolean {
    return !this.id;
  }

  get hasValue(): boolean {
    throw new Error('hasValue() getter is not implemented');
  }

  serialize(): OrderTrackingModel {
    return {
      id: this.id,
      lineItemSeqNum: this.order,
      orderTypeCode: this.source.orderTypeCode,
      serviceCode: this.source.serviceCode,
      trackingCode: this.source.trackingCode,
    };
  }
}

class OrderTrackingItemDate extends OrderTrackingItem {
  date: string;
  value: string;

  constructor(
    data: OrderTrackingModel = {},
    public source: OrderTrackingSourceModel
  ) {
    super(data, source);

    this.date = data.valueDate || null;
    this.value = this.date ? parseDateTime(this.date).toFormat(DATE_FORMAT_FULL).toUpperCase() : null;
  }

  get hasValue(): boolean {
    return !!this.date;
  }

  serialize(): OrderTrackingModel {
    return {
      ...super.serialize(),
      valueDate: this.date,
    };
  }
}

class OrderTrackingItemText extends OrderTrackingItem {
  text: string;
  value: string;

  constructor(
    data: OrderTrackingModel = {},
    public source: OrderTrackingSourceModel
  ) {
    super(data, source);

    this.text = data.valueText || '';
    this.value = this.text;
  }

  get hasValue(): boolean {
    return !!this.text;
  }

  serialize() {
    return {
      ...super.serialize(),
      valueText: this.text,
    };
  }
}

const orderTrackingFactory = (item: OrderTrackingModel, source: OrderTrackingSourceModel): IOrderTrackingItem => {
  const type = source && source.type ? source.type : null;
  let tracking;

  switch (type) {
    case TYPES.DATE:
      tracking = new OrderTrackingItemDate(item, source);
      break;
    case TYPES.TEXT:
      tracking = new OrderTrackingItemText(item, source);
      break;
    default:
      throw new Error(`orderTrackingFactory: unknown "${type}" type.`);
  }

  return tracking;
};

export class OrderTrackingModelClass {
  constructor(
    public readonly name: string,
    public readonly items: IOrderTrackingItem[] = [] // OrderLineItem[],
  ) {}

  get isEmpty(): boolean {
    return !this.items || this.items.length === 0;
  }

  getItemValue(index: number): string {
    return this.items[index] ? this.items[index].value : '';
  }
}

const SERVICE_CODE_TRNS = 'TRNS';
const SERVICE_CODE_SHCB = 'SHCB';

const ORDER_SOURCES: IOrderSources = {
  informationSessionDate: new OrderTrackingSourceModel('FULFILL', null, /^INFO$/, 'INFO', 'APPTDATE'),
  physicalExamDate: new OrderTrackingSourceModel('FULFILL', null, /^PE$/, 'PE', 'APPTDATE'),
  clearance: new OrderTrackingSourceModel('CUSTOMER', null, /^WU$/, 'WU', 'WUPCOMP'),
  expectedClearance: new OrderTrackingSourceModel('CUSTOMER', null, /^WU$/, 'WU', 'EXPCLEARANCE'),
  verificationReceivedDate: new OrderTrackingSourceModel('CUSTOMER', null, /^WU$/, 'WU', 'CUSTVERRCV'),
  donorResearchSampleDate: new OrderTrackingSourceModel('FULFILL', null, /^DRS$/, 'DR', 'APPTDATE'),
  patientResearchSampleDate: new OrderTrackingSourceModel('FULFILL', null, /^PRS$/, 'PRS', 'APPTDATE'),
  filgrastimStartDate: new OrderTrackingSourceModel('FULFILL', null, /^FIL$/, 'FIL', 'APPTDATE'),
  // MS-8787: added a copy without default option only for Marrow converted orders
  filgrastimStartDate4Marrow: new OrderTrackingSourceModel('FULFILL', null, /^FIL$/, 'FIL', 'APPTDATE', false),
  prepStart: new OrderTrackingSourceModel('CUSTOMER', null, /^WU$/, 'WU', 'TPLNTSTART'),
  tcConfirmedCollectionDate: new OrderTrackingSourceModel('FULFILL', null, /^COLL$/, 'COLL', 'TCCOLLCONFIRM'),
  collection: new OrderTrackingSourceModel('FULFILL', null, /^COLL$/, 'COLL', 'APPTDATE'),
  extendedMedicalCollectionDate: new OrderTrackingSourceModel('FULFILL', null, /^EXPE$/, 'EXPE', 'APPTDATE', false),
  idmCollectionDate: new OrderTrackingSourceModel('FULFILL', null, /^IDM$/, 'IDM', 'APPTDATE', false),
  preCollectionSample: new OrderTrackingSourceModel('FULFILL', null, /^PSAM$/, 'PSAM', 'APPTDATE', false),
  intervalPeDate: new OrderTrackingSourceModel('FULFILL', null, /^IPE3$/, 'IPE3', 'APPTDATE', false),
  nmdpApprovalDate: new OrderTrackingSourceModel('CUSTOMER', null, /^WU$/, 'WU', 'APPRDTENM'),
  dcApprovalDate: new OrderTrackingSourceModel('FULFILL', null, /^COLL$/, 'COLL', 'APPRDTEDC'),
  appointmentDate: new OrderTrackingSourceModel('FULFILL', null, /^TB$/, 'TB', 'APPTDATE'),
  resultsReceivedDate: new OrderTrackingSourceModel('FULFILL', null, null, null, 'RESULTSDATE'),
  ctShipDate: new OrderTrackingSourceModel('FULFILL', null, null, null, 'SHIPDATE'),
  cordShipDate: new OrderTrackingSourceModel('FULFILL', null, /^SHCB$/, 'SHCB', 'SHIPDATE'),
  cordPrepStart: new OrderTrackingSourceModel('CUSTOMER', null, /^ORDER$/, 'ORDER', 'TPLNTSTART'),
  shippingCompany: new OrderTrackingSourceModel(
    'FULFILL',
    null,
    /^TRNS$/,
    SERVICE_CODE_TRNS,
    'SHIPCOMPANY',
    true,
    TYPES.TEXT
  ),
  trackingNumber: new OrderTrackingSourceModel(
    'FULFILL',
    null,
    /^TRNS$/,
    SERVICE_CODE_TRNS,
    'TRANSTRACKING',
    true,
    TYPES.TEXT
  ),
  cryopreservedShipmentDate: new OrderTrackingSourceModel(
    'FULFILL',
    'MISC',
    null,
    CRYO_SERVICE_CODE,
    SHIPDATE_TRACKING_CODE
  ),
};

const ORDER_TYPE_TRACKINGS: MsApp.Dictionary<IOrderSources> = {
  DEFAULT: ORDER_SOURCES,
  CT: {
    ...ORDER_SOURCES,
    resultsReceivedDate: new OrderTrackingSourceModel('FULFILL', null, /^TB$/, null, 'RESULTSDATE'),
  },
  HR: {
    ...ORDER_SOURCES,
    resultsReceivedDate: new OrderTrackingSourceModel('FULFILL', 'TYP', null, null, 'RESULTSDATE'),
  },
  DR: {
    ...ORDER_SOURCES,
    resultsReceivedDate: new OrderTrackingSourceModel('FULFILL', null, /^DR$/, 'DR', 'RESULTSDATE'),
  },
  CCT: {
    ...ORDER_SOURCES,
    resultsReceivedDate: new OrderTrackingSourceModel('FULFILL', 'TYP', null, null, 'RESULTSDATE'),
  },
};

const PHYSICAL_EXAM_EXPIRATION_IN_MONTHS = 6;

export class WorkupDetailsFactory {
  static create(data: IOrderSources, orderType: string = null): MsApp.Dictionary<string | OrderTrackingModelClass> {
    const orderTrackings = ORDER_TYPE_TRACKINGS[orderType] || ORDER_TYPE_TRACKINGS.DEFAULT;
    const workupDetails: MsApp.Dictionary<OrderTrackingModelClass | string> = Object.entries(orderTrackings)
      .map(
        ([name, source]) => new OrderTrackingModelClass(name, WorkupDetailsFactory.findOrderTrackingItems(source, data))
      )
      .reduce((model, item) => {
        model[item.name] = item;
        return model;
      }, {});

    const physicalExamDates = (workupDetails.physicalExamDate as OrderTrackingModelClass).items.sort(
      (first, second) => first.order - second.order
    );

    let intervalPeDueDate = '';
    let i = physicalExamDates.length - 1;
    while (i >= 0 && !intervalPeDueDate) {
      const physicalExamDate = physicalExamDates[i] as OrderTrackingItemDate;
      if (physicalExamDate.date) {
        const intervalPeDueDateTime = parseDateTime(physicalExamDate.date, {zone: 'utc'}).plus({
          months: PHYSICAL_EXAM_EXPIRATION_IN_MONTHS,
        });
        intervalPeDueDate = formatViewDate(intervalPeDueDateTime.toISO());
      }
      i -= 1;
    }
    workupDetails.intervalPeDueDate = intervalPeDueDate;

    const tcConfirmedCollectionDates = (workupDetails.tcConfirmedCollectionDate as OrderTrackingModelClass).items.map(
      item => item.order
    );
    const collectionDates = (workupDetails.collection as OrderTrackingModelClass).items.map(item => item.order);

    const missedTcConfirmedCollectionDates = collectionDates.filter(item => !tcConfirmedCollectionDates.includes(item));
    const missedcollectionDates = tcConfirmedCollectionDates.filter(item => !collectionDates.includes(item));

    missedTcConfirmedCollectionDates.forEach(lineItemSeqNum => {
      (workupDetails.tcConfirmedCollectionDate as OrderTrackingModelClass).items.push(
        orderTrackingFactory({lineItemSeqNum}, ORDER_SOURCES.tcConfirmedCollectionDate)
      );
    });
    missedcollectionDates.forEach(lineItemSeqNum => {
      (workupDetails.collection as OrderTrackingModelClass).items.push(
        orderTrackingFactory({lineItemSeqNum}, ORDER_SOURCES.collection)
      );
    });
    (workupDetails.tcConfirmedCollectionDate as OrderTrackingModelClass).items.sort(
      (item1, item2) => item1.order - item2.order
    );
    return workupDetails;
  }

  // delete all cryo items with trackingCode equals'CBURCVD' if there is trackingCode equals SHIPDATE_TRACKING_CODE or
  // there is no need to use the cryo item to replace value from bppFullfilment
  // patch for MS-26745, MS-26625
  static filterCryoTrackings(
    source: OrderTrackingSourceModel,
    items: OrderTrackingModel[],
    bdpFulfillment: OrderTrackingModel[]
  ): OrderTrackingModel[] {
    if (source.serviceCode === CRYO_SERVICE_CODE) {
      const hasCryoShipped = items.find(
        item => item.serviceCode === CRYO_SERVICE_CODE && item.trackingCode === SHIPDATE_TRACKING_CODE
      );
      const noShcbBpdFullfilmentWithShipDate = !bdpFulfillment?.find(
        bdp => bdp.serviceCode === SERVICE_CODE_SHCB && bdp.trackingCode === SHIPDATE_TRACKING_CODE
      );

      if (hasCryoShipped || noShcbBpdFullfilmentWithShipDate) {
        return items.filter(
          item =>
            item.serviceCode === CRYO_SERVICE_CODE &&
            !(item.serviceCode === CRYO_SERVICE_CODE && item.trackingCode === CBURCVD_TRACKING_CODE)
        );
      }
    }

    return items;
  }

  static findOrderTrackingItems(source: OrderTrackingSourceModel, data: IOrderSources): IOrderTrackingItem[] {
    const items = WorkupDetailsFactory.filterCryoTrackings(
      source,
      flatten([data.customer, data.fulfillment]),
      data.bdpFulfillment
    )
      .filter(item => source.isEqual(item))
      .map(item => orderTrackingFactory(item, source));

    const bdpFulfillmentOrder = data.bdpFulfillment?.find(
      bdp => bdp.serviceCode === SERVICE_CODE_SHCB && bdp.trackingCode === SHIPDATE_TRACKING_CODE
    );

    if (source.isDefault && items.length === 0) {
      items.push(orderTrackingFactory({}, source));
      return items;
    }

    if (source.serviceCode === CRYO_SERVICE_CODE) {
      const maxTrackingItem = items.reduce((max, item) => {
        if (max.lineItemSeqNum > item.lineItemSeqNum) {
          return max;
        }
        if (max.lineItemSeqNum < item.lineItemSeqNum) {
          return item;
        }

        return max.id > item.id ? max : item;
      });

      if (bdpFulfillmentOrder) {
        maxTrackingItem.date = bdpFulfillmentOrder.valueDate;
        maxTrackingItem.value = parseDateTime(bdpFulfillmentOrder.valueDate).toFormat(DATE_FORMAT_FULL).toUpperCase();
      }

      return [maxTrackingItem];
    }

    return source.trackingCode === 'WUPCOMP'
      ? items.sort((first, second) => second.order - first.order)
      : items.sort((first, second) => first.order - second.order);
  }
}
